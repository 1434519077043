#other-days {
  margin-top: 40px;
  width: 100%;
}
#other-days > .container {
  display: flex;
  width: 100%;
  overflow-x: scroll;
}
#other-days > .container > .weather-card {
  flex-shrink: 0;
  padding: 10px;
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: 1.4px solid #395B64;
  cursor: pointer;
}
#other-days > .container > .weather-card > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
#other-days > .container > .weather-card > .icon > i {
  font-size: 20px;
}
#other-days > .container > .weather-card > .day {
  font-size: 13px;
}
#other-days > .container > .active {
  background-color: whitesmoke;
  color: #2C3333;
}