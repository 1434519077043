.Loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(155, 137, 137, 0.347);
}

.Loading>.circle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 10vmax;
    height: 10vmax;
    background-color: black;
    animation: load 1s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes load {
    0% {
        width: 10vmax;
        height: 10vmax;
    }

    50% {
        width: 20vmax;
        height: 20vmax;

    }

    100% {
        width: 10vmax;
        height: 10vmax;
    }
}