.Navigation {
  margin-bottom: 15px;
}
.Navigation > nav {
  display: flex;
  justify-content: center;
}
.Navigation > nav > a {
  text-decoration: none;
  color: white;
  padding: 10px;
  border: 2px solid #395B64;
}
.Navigation > nav > a.active {
  background-color: red;
}