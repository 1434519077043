#current-day {
  display: flex;
}
#current-day > .col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33%;
}
#current-day > .left {
  gap: 4px;
}
#current-day > .left .city-name {
  font-size: 2.5rem;
}
#current-day > .left .day {
  font-size: 1.5rem;
}
#current-day > .left .current-hour {
  font-size: 1.5rem;
}
#current-day > .center {
  display: flex;
  justify-content: center;
  font-size: 20px;
}
#current-day > .center > div {
  display: flex;
  justify-content: center;
}
#current-day > .center > .icon {
  padding: 20px;
}
#current-day > .center > .icon > i {
  font-size: 9vmax;
  color: #E7F6F2;
  color: #cfebf3;
}
#current-day > .right > .container {
  width: 60%;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  gap: 10px;
}
#current-day > .right > .container > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
#current-day > .right > .container > div.humidity .humidity-value {
  display: flex;
  align-items: center;
}
#current-day > .right > .container > div > .max-value svg {
  color: rgb(125, 6, 6);
}
#current-day > .right > .container > div > .min-value svg {
  color: rgb(6, 121, 6);
}
#current-day > .right > .container > div > .humidity-value svg {
  font-size: 25px;
}

@media screen and (max-width: 400px) {
  #current-day > .left .city-name {
    font-size: 1.9rem;
  }
  #current-day > .left .day {
    font-size: 1.5rem;
  }
  #current-day > .right > .container {
    width: 90%;
    font-size: 13px;
    gap: 5px;
  }
  #current-day > .right > .container > div > .humidity-value svg {
    font-size: 20px;
  }
}